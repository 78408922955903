@use 'sass:map';
@use './../abstracts' as *;

/*** Navbar ***/
.navbar.sticky-top {
  top: -100px;
  transition: 0.5s;
}

.navbar {
  .navbar-nav .nav-link {
    padding: 25px 0;
    color: map-get($map: $colors, $key: primary);
    font-weight: 500;
    outline: none;
  }

  .nav-item {
    margin-left: 30px;

    @media (max-width: 992px) {
      margin-left: 0;
    }
  }

  .navbar-nav .nav-link:hover,
  .navbar-nav .nav-link.active {
    color: map-get($map: $colors, $key: secondary);
  }

  .dropdown-toggle::after {
    border: none;
    content: '\f107';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    vertical-align: middle;
    margin-left: 8px;
  }

  .dropdown-menu {
    overflow: hidden;
    font-size: 0.85rem;
    min-width: max-content;
    left: 0 !important;

    @media (max-width: 992px) {
      background-color: transparent !important;
      border: none !important;
    }

    .dropdown-item {
      padding: 0.5rem 1rem;
      color: map-get($map: $colors, $key: primary);

      &:active,
      &:hover {
        background-color: map-get($map: $colors, $key: secondary);
        color: #fff;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .navbar .navbar-nav .nav-link {
    margin-left: 0;
    padding: 10px 0;
  }

  .navbar .navbar-nav {
    margin-top: 25px;
    border-top: 1px solid #eeeeee;
  }
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: block;
    border: none;
    margin-top: 0;
    top: 150%;
    right: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
  }

  .navbar .nav-item:hover .dropdown-menu {
    top: 100%;
    visibility: visible;
    transition: 0.5s;
    opacity: 1;
  }
}
