@use "sass:map";
@use "./../abstracts" as *;

// Write the resets here

html {
  scroll-behavior: smooth;
}

body {
  // @include font();
  font-size: 0.875rem;
  overflow-x: hidden;
  color: map-get($map: $colors, $key: texg);
}

a,
button {
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

main {
  min-height: calc(100vh - (90px + 45px));
}

h1,
h2,
h3,
h4,
h5,
h6 {
  &.ant-typography {
    font-family: "DM Serif Display", serif;
    font-weight: 400;
  }
}

label {
  font-weight: 600;
}

.text-primary {
  color: map-get($map: $colors, $key: primary) !important;
}
