
// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less


@font-family: "Archivo", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';
@primary-color: @blue-9;
@font-size-base: 14px;
@font-size-lg: 14px;

@input-padding-horizontal: 1rem;
@input-padding-vertical-base: .5rem;



// Layout
@layout-body-background: #fff;
@layout-header-background: #fff;
@layout-header-height: 48px;
@layout-header-padding: 0 16px;
@layout-header-color: @text-color;
@layout-footer-padding: 24px 50px;
@layout-footer-background: @layout-body-background;
@layout-sider-background: @layout-header-background;
@layout-trigger-height: 48px;
@layout-trigger-background: #fff;
@layout-trigger-color: @primary-color;
@layout-zero-trigger-width: 36px;
@layout-zero-trigger-height: 42px;



// Menu
// ---
@menu-inline-toplevel-item-height: 40px;
@menu-item-height: 40px;
@menu-item-group-height: @line-height-base;
@menu-collapsed-width: 80px;
@menu-bg: @component-background;
@menu-popup-bg: @component-background;
@menu-item-color: @text-color;
@menu-inline-submenu-bg: @background-color-light;
@menu-highlight-color: @primary-color;
@menu-highlight-danger-color: @error-color;
@menu-item-active-bg: @primary-color;
@menu-item-active-danger-bg: @red-1;
@menu-item-active-border-width: 3px;
@menu-item-group-title-color: @text-color-secondary;
@menu-item-vertical-margin: 4px;
@menu-item-font-size: @font-size-base;
@menu-item-boundary-margin: 8px;
@menu-item-padding-horizontal: 20px;
@menu-item-padding: 0 @menu-item-padding-horizontal;
@menu-horizontal-line-height: 46px;
@menu-icon-margin-right: 10px;
@menu-icon-size: @menu-item-font-size;
@menu-icon-size-lg: @font-size-lg;
@menu-item-group-title-font-size: @menu-item-font-size;

@page-header-ghost-bg: #F5F5F5;
@link-color: @primary-color;
@modal-mask-bg: fade(@white, 60%);
@modal-body-padding: 50px;
// overrides

// .ant-form-item{
//   margin-bottom: 14px;
// }

.ant-modal-mask{
  backdrop-filter: blur(2px);
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  padding-block: 4px;
  height: 38px;
}

.ant-modal-body{
 padding-top: 1rem;
}
